




















import { Component, Vue } from 'vue-property-decorator'
import PageLayout from '@/components/common/page-layout/PageLayout.vue'
import ContentArea from '@/components/common/ContentArea.vue'
import PrimaryButton from '@/components/common/PrimaryButton.vue'
import isOS from '@/helper/detectOs'

@Component({
  components: {
    PageLayout,
    ContentArea,
    PrimaryButton
  }
})
export default class SplashScreen extends Vue {
  get preferMobileHintVisible (): boolean {
    return !this.$device.mobile
  }

  private mounted (): void {
    this.loggedOut()

    if (window.innerWidth < this.$data.mobileWidth && isOS()) window.scrollTo(0, 1)
  }

  private loggedOut (): void {
    this.$store.dispatch('walkthrough/clearResults')
      .then(() => {
        sessionStorage.clear()
        this.$store.dispatch('auth/setUpdatedOrganizationCode', null)
        this.$store.dispatch('timer/clear', null)
        window.localStorage.removeItem('vuex')
      })
  }
}
